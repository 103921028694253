import React from 'react';
import Footer from '../components/footer/footer';

function HomeFooter() {
    return (
        <div>
            <Footer />
        </div>
    );
}

export default HomeFooter;