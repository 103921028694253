export const genId = (() => {
  let count = 0;
  return () => {
    return (++count).toString();
  };
})();

export const createRectRef =
  (onRect: (rect: DOMRect) => void) => (el: HTMLElement | null) => {
    if (el) {
      setTimeout(() => {
        const boundingRect = el.getBoundingClientRect();
        onRect(boundingRect);
      });
    }
  };

export const prefersReducedMotion = (() => {
  // Cache result
  let shouldReduceMotion: boolean | undefined = undefined;

  return () => {
    if (shouldReduceMotion === undefined && typeof window !== 'undefined') {
      const mediaQuery = matchMedia('(prefers-reduced-motion: reduce)');
      shouldReduceMotion = !mediaQuery || mediaQuery.matches;
    }
    return shouldReduceMotion;
  };
})();
