import React from "react";
import "./recommendations.css";
import RecommendedVideos from "./recommendedVideos";

function Recommendations() {
	return (
		<div className="recommendationsDiv">
			<div className="recommendations">
				<h3>The world's largest selection of courses</h3>
				<p style={{ marginBottom: 10 }}>
					Choose from 130,000 online video courses with new additions published
					every month
				</p>

				<RecommendedVideos type={"categories"} />
				<h2 style={{ marginTop: 40, marginBottm: 100 }}>
					Students are viewing
				</h2>
				<div style={{ height: 0 }}></div>
				<RecommendedVideos type={"courses"} />
			</div>
		</div>
	);
}

export default Recommendations;
